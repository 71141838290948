import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Lang,
  Breakpoint,

  useDimensions,

  TextBlock,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';

import handleLocalUrlChange from '../../../functions/handleLocalUrlChange';
import handleHeadingPrioritization from '../../../functions/handleHeadingPrioritization';

import Arrow from './Arrow';

export default function TextBlockComponent(props) {
  const { i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;
  const hasBlueprint = props.params.blueprint !== 'single';

  const [position, setPosition] = React.useState('');

  const getPosition = () => {
    if (size.width < Breakpoint.sm && props.data.align !== undefined) return props.data.align;
    if (hasBlueprint && props.params.mirror) return 'right';
    if (hasBlueprint) return 'left';
    return 'center';
  };

  React.useEffect(() => {
    setPosition(getPosition());
  }, []);

  return (
    <motion.div
      style={{
        height: '100%',
        minHeight: props.params?.index === 0 ? '30vh' : 'auto',
        width: '100%',
        position: 'relative',
        paddingTop: props.data?.arrow ? 100 : 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
    >
      {
        (props.data.arrow && position === 'left') && (
          <Arrow
            align="left"
            text={props.data.arrow[lang]}
            size={size}
          />
        )
      }
      <motion.div
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
          ease: 'easeInOut',
          stiffness: 100,
        }}
        viewport={{
          once: true,
        }}
        style={{
          marginTop: props.data.arrow
            ? 0
            : (size.width < Breakpoint.sm)
              ? -15
              : 30,
          paddingBottom: 30,
        }}
      >
        {
          (props.data.title !== undefined || props.data.text !== undefined) && (
            <TextBlock
              title={{
                content: props.data.title !== undefined && (
                  props.data.title[lang]
                ),
                portableText: props.data.title !== undefined,
                fontSize: (props.params.length === props.params.index + 1) ? '2em' : undefined,
                heading: handleHeadingPrioritization(props.params.index),
              }}
              text={{
                content: props.data.text !== undefined && (
                  props.data.text[lang]
                ),
                portableText: props.data.text !== undefined,
              }}
              align={
                size.width < Breakpoint.sm
                  ? props.data.align
                  : hasBlueprint ? 'left' : 'center'
              }
              buttons={
                props.data.buttons !== undefined
                && props.data.buttons.map((item) => ({
                  /* eslint-disable no-underscore-dangle */
                  id: item._key,
                  text: item.link.text === 'custom' ? item.link.custom_text[lang] : item.link.text[lang],
                  colorScheme: item.colorscheme,
                  onClick: () => window.open(handleLocalUrlChange(item.link.url), item.link.target),
                }))
              }
            />
          )
        }
        {
          props.data.app_store_buttons && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 15,
                marginTop: 30,
              }}
            >
              <a
                href="https://apps.apple.com/nl/app/blue-current/id1447733712?itscg=30200&itsct=apps_box_badge&mttnsubad=1447733712"
                rel="noreferrer"
                target="_blank"
                style={{
                  display: 'inline-block',
                }}
              >
                <img
                  src={
                    lang === 'en'
                      ? 'https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1555718400'
                      : 'https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/nl-nl?releaseDate=1555718400'
                  }
                  alt="Download on the App Store"
                  style={{
                    width: 150,
                    height: 50,
                    verticalAlign: 'middle',
                    objectFit: 'contain',
                  }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.bluecurrent.bcuweb"
                rel="noreferrer"
                target="_blank"
                style={{
                  display: 'inline-block',
                }}
              >
                <img
                  src={
                    lang === 'en'
                      ? 'https://cdn.sanity.io/images/pnajo2cq/production/d3ec3c38d6fda2e589d860c57721e7c25ccecbdd-270x80.png'
                      : 'https://cdn.sanity.io/images/pnajo2cq/production/93d4e0d687ab01d57f65578719b9d04a659bc0d1-270x80.png'
                  }
                  alt="Get in on Google Play"
                  style={{
                    width: 'auto',
                    height: 50,
                    verticalAlign: 'middle',
                    objectFit: 'contain',
                  }}
                />
              </a>
            </div>
          )
        }
      </motion.div>
      {
        (props.data.arrow && (position === 'right' || position === 'center')) && (
          <Arrow
            align="right"
            text={props.data.arrow[lang]}
            size={size}
          />
        )
      }
    </motion.div>
  );
}
